import { Link } from "gatsby"
import r from "../../services/Router"
import t from '../../services/GetTranslationValue'
import VideocamIcon from "@material-ui/icons/Videocam"
import FavoriteIcon from "@material-ui/icons/Favorite"
import React from "react"
import styles from './media-item.module.scss'
import { Stream } from "@cloudflare/stream-react";
import Skeleton from "@material-ui/lab/Skeleton"
import { getLocaleAlias } from "../../graphql-assistant/createLocaleTextGetter"

const MediaItem = ({ locale, translations, x, setMediaItems, hideSkeletons }) => {

  const index = 0;
  return [<div className={`${styles.item} ${hideSkeletons ? "" : styles.mediaHidden}`}>
    <Link className={`gamble-hidden-md-down ${styles.coverPic}`} to={`/${getLocaleAlias(locale)}/${t.get(translations, 'big-wins')}/w/${x.title && x.title.toLowerCase().replace(/\s/g, "-") + '-' + x.id}`} state={{ modal: true, item: x, index: index }}>
      {x.type === "image" && <img id="media-item" src={`${x.mediaUrl}?fit=crop&w=500&h=282`} alt={x.title}/>}
      {x.type === "video" &&
        <img src={`https://videodelivery.net/${x.mediaId}/thumbnails/thumbnail.jpg?time=0s&width=500&height=282`} alt={x.title}/>
      }
    </Link>
    <Link state={{modal:true}} className={`gamble-hidden-md-up ${styles.coverPic}`} to={`/${getLocaleAlias(locale)}/${t.get(translations, 'big-wins')}/w/${x.title && x.title.toLowerCase().replace(/\s/g, "-") + '-' + x.id}/`}>
      {x.type === "image" && <img id="media-item" src={`${x.mediaUrl}?fit=crop&w=500&h=282`} alt={x.title} />}
      {x.type === "video" &&
        <img src={`https://videodelivery.net/${x.mediaId}/thumbnails/thumbnail.jpg?time=0s&width=500&height=282`} alt={x.title} />
      }
    </Link>
    {x.type === "video" && <VideocamIcon className={`${styles.videoIcon}`} />}
    <span className={`${styles.duration}`}>{x.videoDuration}</span>
    <div className={`${styles.mediaDetails}`}>
      <p className={`${styles.title}`}><span>{`${Math.round(x.win / x.bet)}x`}</span><span>{`${t.get(translations, 'win-on-media')} ${x.game}`}</span></p>
      <div className={`${styles.winDetails}`}>
        <p className={`${styles.detRow}`}>
          <label>{t.get(translations, 'filter-big-wins-bet')}/{t.get(translations, 'filter-big-wins-win')}: </label>
          <span>{x.currency}{x.bet} / {x.currency}{x.win}</span>
        </p>
      </div>
      <div className={`${styles.dateContainer}`}>
        <span className={`${styles.username}`}>{x.username}</span>
        <span className={`${styles.separator}`}>|</span>
        <span className={`${styles.date}`}>{x.date && x.date.slice(0, 10)}</span>
      </div>
    </div>
    <span className={`${styles.likes}`}>{x.likes}<FavoriteIcon/></span>
    </div>,
    <div className={`${styles.item} ${hideSkeletons ? styles.mediaHidden : ""}`}>
      <Skeleton animation="wave" variant="rect" width={322} height={180} />
      <Skeleton variant="text" width={322} height={30} />
      <Skeleton variant="text" width={322} height={30} />
    </div>]
}

export default MediaItem