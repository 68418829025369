import React, { useContext, useState, useEffect } from "react"
import Layout from "../../components/layout"
import { graphql, useStaticQuery, Link } from "gatsby"
import t from '../../services/GetTranslationValue'
import r from '../../services/Router'
import h from "../../services/GenericHelpers"
import NullIf from "../../services/NullIf"
import SEO from "../../components/seo"
import Img from "gatsby-image"
import ContentWrapper from "../../components/text-helpers/content-wrapper"
import { Button, IconButton, } from "@material-ui/core"
import styles from "./item.module.scss"
import ThemeContext from "../../context/ThemeContext"
import Dialog from '@material-ui/core/Dialog';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Skeleton from '@material-ui/lab/Skeleton';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { fetchMedia, fetchMedias, fetchMediasWithFilter, likeMedia } from "../../api/client/media"
import { Stream } from "@cloudflare/stream-react";
import { videoItem } from "./video-item";
import Login from "../../components/login/login"
import { isLoggedIn } from "../../services/auth";
import { getLocaleAlias } from "../../graphql-assistant/createLocaleTextGetter"
import ShareIcon from '@material-ui/icons/Share';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import CasinoHelper from "../../services/casino-bonus-helpers"
import BlockIcon from '@material-ui/icons/Block';
import { getMaxBetText } from "../../services/casino-property-renders"

const Content = ({ translations, stub, locale, mediaDetails, hideSkeletons, setMediaItems, mediaIndex, setMediaItem, bonuses}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  let [loginOpen, setLoginOpen] = useState(false)
  let [loginIndex, setLoginIndex] = useState(0)

  const makeFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const lpClose = () => {
    setLoginOpen(false);
  }

  const toggleLoginPopup = (index) => {
    setLoginOpen(true);
    setLoginIndex(index);
  }

  const handleLike = (id, oldVote) => async event => {

    !isLoggedIn() && toggleLoginPopup(0)

    event.preventDefault()

    //console.log({oldVote})
    setIsLiked(!isLiked);

    const res = await likeMedia(id, oldVote > 0 ? 0 : 1)
    fetchMedia(id)
        .then(items => {
          const [ item ] = items.medias
          fetchMedias('both', null)
            .then(medias => {
              setMediaItems(medias.medias.map(item => videoItem(item)))
            })
            .catch(err => console.log(err))

          setMediaItem(item)
        })
        .catch(err => console.log(err))
  }

  const data = useStaticQuery(graphql`
    {
      allSanityGame {
        edges {
          node {
            name
          }
        }
      }
    }
  `)

  const games = data.allSanityGame.edges;

  const getCasino = (casinos, translations, mediaDetails) => {
    const casino = casinos.find(x => x.name === mediaDetails.casinoId);
    if (casino)
       return <FloatingInfoCard translations={translations} casino={casino} locale={locale} />
    return <div className={`${styles.lonelyCasino}`}>
      <label>{t.get(translations, "big-wins-detail-casino-where-played")}</label>
      <span>{mediaDetails.casinoId}</span>
    </div>
  }

  const getSummaryText = (casino, translations) => {
    let fdb = CasinoHelper.getBonusToUse(casino, false)
    if (fdb && fdb.bonusValue)
      return `${fdb.bonusValue}% / ${fdb.maximumBonusAmount}${t.get(
        translations,
        "currency"
      )}`
    return "-"
  }

  const getFreeSpinsText = casino => {
    let freespinNoDepositBonus = CasinoHelper.GetFreespinNoDepositBonus(casino)
    if (freespinNoDepositBonus) return freespinNoDepositBonus.freespinsAmount
    return "-"
  }

  const getWageringText = (casino, translations) => {
    let bonus = CasinoHelper.getBonusToUse(casino, false)
    if (bonus && bonus.wageringRequirement && bonus.wageringRequirementType)
      return `${bonus.wageringRequirement}${t.get(translations, bonus.wageringRequirementType)}`
    if (!bonus)
      return '-'
    return "0x"
  }

  const getBonusCodeText = casino => {
    let bonus = CasinoHelper.getBonusToUse(casino, false)
    if (bonus && bonus.bonusCode) return bonus.bonusCode
    return "-"
  }

  const FloatingInfoCard = ({ casino, translations, locale, reviews }) => {

    const summary = getSummaryText(casino, translations)
    const freeSpins = getFreeSpinsText(casino)
    const wageringText = getWageringText(casino, translations)
    const bonusCodeText = getBonusCodeText(casino)

    return (
        <CasinoDataTable smallLogo={casino.smallLogo} name={casino.name} translations={translations} casino={casino}
          minimumDeposit={casino.minimumDeposit} maxBet={casino.maxBet} summary={summary}
          wageringText={wageringText} amountOfSlots={casino.amountOfSlots}/>
    )
  }

  const addNamedClass = (casino, suffix) => {
    if (casino.name === 'Rizk')
      return casino.name + '-' + suffix;

    return '';
  }

  const CTAArea = ({ casino, locale, translations, params }) => {

    return <div className={`${styles.ctaParent}`}>
      <a
        target={`_blank`} rel={`noopener noreferrer nofollow`}
        id={`${addNamedClass(casino, "play-now-redirect")}`}
        href={r.get(locale, translations, "redirectPage", params)}
        className={`${styles.casinoCTA} ${casino.extendedData && casino.extendedData.closed === true ? styles.casinoClosed : ""}`}
      >
        <Button
          variant="contained"
          color="primary"
          id={`${addNamedClass(casino, "play-now-button")}`}
          disabled={casino.extendedData && casino.extendedData.closed === true}
        >
          <span
            id={`${addNamedClass(casino, "play-now-button-text")}`}>
            {casino.extendedData && casino.extendedData.closed === true ?
              <span className={`${styles.ctaCasinoClosed}`}>
                <BlockIcon />
                {t.get(translations, 'casino-closed')}!
            </span>
              :
              t.get(translations, "casino-review-play")
            }
          </span>
        </Button>
      </a>
      {locale === "enGB" &&
        <p className={`${styles.warnings}`}>
          <span>{t.get(translations, "casino-review-gamble-responsively")} | </span>
          <a target={`_blank`} rel={`noopener noreferrer nofollow`} href="http://gambleaware.org/">gambleaware.org</a>
          <span> | +18</span>
          <span
            className={`${styles.termsAndConditions}`}
            id={`${addNamedClass(casino, "terms-and-conditions")}`}
          >
            <a
              target={`_blank`}
              rel={`noopener noreferrer nofollow`}
              href={casino.casinoTermsLink ? casino.casinoTermsLink : r.get(locale, translations, "redirectPage", params)}
            >
              {`${t.get(translations, "terms-and-conditions")} apply`}
            </a>
          </span>
        </p>
      }
    </div>
  }

  const CasinoDataTable = ({ smallLogo, name, translations, casino, minimumDeposit, maxBet, summary, wageringText, amountOfSlots }) => {
    return <div className={`${styles.casinoInfo}`}>
      <div className={`${styles.logoWrapper}`}>
        {smallLogo && smallLogo.asset &&
          <div className={`${styles.bannerLogo} ${casino.extendedData && casino.extendedData.closed === true ? styles.closedCasino : ""}`}>
            <img
              style={{ width: 100, height: 100, borderRadius: '50%' }}
              srcset={smallLogo.asset.fluid.srcSet}
              src={smallLogo.asset.fluid.src}
              alt={casino.name}
            />
          </div>
        }
        <p className={`${styles.casinoName}`}>{name}</p>
      </div>
      <div className={`${styles.rowContainer}`}>
        <p>
          <label>{t.get(translations, "casino-review-minimum-deposit")}: </label>
          <span>{minimumDeposit}{t.get(translations, 'currency')}</span>
        </p>
        <p>
          <label>{t.get(translations, "casino-review-max-bet")}: </label>
          <span>{getMaxBetText(translations, maxBet)}</span>
        </p>
        <p>
          <label>{t.get(translations, "casino-review-1st-deposit-bonus")}: </label>
          <span>{summary}</span>
        </p>
        <p>
          <label>{t.get(translations, "casino-review-wagering-requirements")}: </label>
          <span>{wageringText}</span>
        </p>
        <p>
          <label>{t.get(translations, "casino-review-number-of-slots")}: </label>
          <span>{amountOfSlots}</span>
        </p>
      </div>
      <CTAArea casino={casino} locale={locale} translations={translations} params={casino.affiliateMask} />
    </div>
  }

  return (
    <div className={`modal-main-wrapper ${styles.mainWrapper}`}>
      {hideSkeletons ?
        <div className={`bw-media ${styles.media}`}>
          {mediaDetails.type === "video" ?
            <Stream
              controls
              src={mediaDetails.extMediaId}
            />
            :
            <div>
              <img
                src={mediaDetails.url}
                id="popup-media"
              />
              <Dialog fullwidth maxWidth="lg" open={isFullScreen} onClose={makeFullScreen} aria-labelledby={mediaDetails.title}>
                <img
                  src={mediaDetails.url}
                  style={{ margin: '0px' }}
                />
                <FullscreenExitIcon className={`${styles.fullScreenClose}`} onClick={makeFullScreen} />
              </Dialog>
            </div>
          }
        </div>
        :
        <div className={`bw-media ${styles.media} ${styles.loading}`}>
          <Skeleton className={`${styles.skeletons} ${styles.skeletonBody}`} variant="rect" width="100%" height="100%" animation="wave" />
        </div>
      }
      <div className={`bw-content ${styles.content}`}>
        <div className={`${styles.mediaActions}`}>
          {mediaDetails.userVote > 0 ?
            <FavoriteIcon className={`${styles.isLikedIcon}`} onClick={handleLike(mediaDetails.id, mediaDetails.userVote)} />
            :
            <FavoriteBorderIcon className={`${styles.isNotLikedIcon}`} onClick={handleLike(mediaDetails.id, mediaDetails.userVote)} />
          }
          <p className={`${styles.share}`}>
            <Link to={`/${getLocaleAlias(locale)}/${t.get(translations, 'big-wins')}/w/${mediaDetails.title && mediaDetails.title.toLowerCase().replace(/\s/g, "-") + '-' + mediaDetails.id}/`}>
              <ShareIcon />
            </Link>
          </p>
          {mediaDetails.type === "video" ? "" : <ZoomOutMapIcon className={`${styles.fullScreenIcon}`} onClick={makeFullScreen} />}
        </div>
        <div className={`bw-header-info ${styles.headerInfo}`}>
          <h1 className={`${styles.title}`}>{mediaDetails.title && mediaDetails.title.toLowerCase()}</h1>
          <p className={`${styles.username}`}>{mediaDetails.User && mediaDetails.User.username}<span className={`${styles.seperator}`}>|</span></p>
          <span className={`${styles.date}`}>{mediaDetails.createdAt && mediaDetails.createdAt.slice(0, 10)}<span className={`${styles.seperator}`}>|</span></span>
          <span className={`${styles.likes}`}>{mediaDetails.likes} {t.get(translations, "big-wins-detail-likes")}</span>
          <Login isOpen={loginOpen} tabIndex={loginIndex} lpClose={lpClose} />
        </div>
        <hr />
        <div className={`${styles.winDetails}`}>
          <div className={`${styles.betDetails}`}>
            <p>
              <label>{t.get(translations, "big-wins-detail-spin-stake")}:</label>
              <span>{mediaDetails.currency} {mediaDetails.bet}</span>
            </p>
            <p>
              <label>{t.get(translations, "big-wins-detail-win-amount")}:</label>
              <span>{mediaDetails.currency} {mediaDetails.win} ({(mediaDetails.win / mediaDetails.bet).toFixed(0)}x)</span>
            </p>
          </div>
          <div className={`${styles.casinoInfoCard}`}>
            <label>{t.get(translations, "big-wins-detail-game")}:</label>
            {/* {games.some(x => x.node.name === mediaDetails.gameId) ?
              // <Link to={r.get(locale, translations, `slotBonus`, mediaDetails.gameId)}>
              //   {mediaDetails.gameId}
              // </Link>
              <span >{mediaDetails.gameId}</span>
              :
            } */}
            <span className={`${styles.gameName}`}>{mediaDetails.gameId}</span>
          </div>
          {getCasino(bonuses, translations, mediaDetails)}
        </div>
      </div>
      <div className={`${styles.relatedMedia}`}>

      </div>
    </div>
  )
}

const Media = ({ data, pageContext, location, setMediaItems, stub = null }) => {

  let { translations, locale, bonuses } = useContext(ThemeContext)

  let arr = stub.split('-')
  let len = arr.length, id = arr[arr.length - 1]

  let [ mediaItem, setMediaItem ] = useState({})
  let [ hideSkeletons, setHideSkeletons ] = useState(false)

  useEffect(() => {
    async function getTheData() {
      const abortController = new AbortController();
      const signal = abortController.signal
      id &&
      fetchMedia(id, signal)
        .then(items => {
          const [ item ] = items.medias
          setMediaItem(item)
          setHideSkeletons(true)
        })
        .catch(err => console.log(err))
      return function cleanup() {
        abortController.abort()
      }
    }
    getTheData()
  }, [id])

  return (
    <Content
      translations={translations}
      locale={locale}
      mediaDetails={mediaItem}
      mediaIndex={1}
      hideSkeletons={hideSkeletons}
      setMediaItem={setMediaItem}
      setMediaItems={setMediaItems}
      bonuses={bonuses}
    />
  )
}
export default Media