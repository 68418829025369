import React, { useContext, useState, useEffect } from "react"
import Layout from "../../components/layout"
import styles from "./index.module.scss"
import ThemeContext from "../../context/ThemeContext"
import t from '../../services/GetTranslationValue'
import r from "../../services/Router"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import ContentWrapper from '../../components/text-helpers/content-wrapper'
import Media from './item'
import SEO from "../../components/seo"
import localize from "../../components/localize"
import FavoriteIcon from '@material-ui/icons/Favorite';
import VideocamIcon from '@material-ui/icons/Videocam';
import Skeleton from '@material-ui/lab/Skeleton';
import MediaSearch from "../../components/media-search/media-search"
import { fetchMedias } from "../../api/client/media"
import { Router } from "@reach/router"
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import { videoItem } from "./video-item"
import Button from '@material-ui/core/Button';
import Login from "../../components/login/login"
import { isLoggedIn, getUser } from "../../services/auth";
import MediaUploader from '../../components/media-upload/index';


const Content = ({ mediaItems, setMediaItems, translations, locale, filters, hideSkeletons, gamesData, casinosData, benefitsData }) => {

  const user = getUser()

  let [loginOpen, setLoginOpen] = useState(false)
  let [loginIndex, setLoginIndex] = useState(0)
  let [uploadOpen, setUploadOpen] = useState(false);

  const mediaFilters = [
    { baseName: t.get(translations, 'filter-big-wins-latest') },
    { baseName: t.get(translations, 'filter-big-wins-biggest-win') },
    { baseName: t.get(translations, 'filter-big-wins-bet') },
    { baseName: t.get(translations, 'filter-big-wins-win') },
  ]

  const lpClose = () => {
    setLoginOpen(false);
  }

  const toggleLoginPopup = (index) => {
    setLoginOpen(true);
    setLoginIndex(index);
  }
  
  const mediaUploadClose = () => {
    setUploadOpen(false);
  }

  const toggleUploadPopup = () => {
    setUploadOpen(!uploadOpen);
  };

  const leftBenefit = (benefitsData) => {
    return benefitsData.node._rawContent && benefitsData.node._rawContent.map(x => {
      if (x.style === "h2") {
        return <h2>{x.children[0].text}</h2>
      }
      if (x.style === "h3") {
        return <h3>{x.children[0].text}</h3>
      }
      if (x.style === "normal" && x.listItem === "bullet") {
        return <li>{x.children[0].text}</li>
      }
      if (x.style === "normal" && x.listItem !== "bullet") {
        return <p>{x.children[0].text}</p>
      }
    })
  }

  const rightBenefit = (benefitsData) => {
    return benefitsData.node._rawContent && benefitsData.node._rawContent.map(x => {
      if (x.style === "h2") {
        return <h2>{x.children[0].text}</h2>
      }
      if (x.style === "h3") {
        return <h3>{x.children[0].text}</h3>
      }
      if (x.style === "normal" && x.listItem === "bullet") {
        return <li>{x.children[0].text}</li>
      }
      if (x.style === "normal" && x.listItem !== "bullet") {
        return <p>{x.children[0].text}</p>
      }
    })
  }

  const middleBenefit = (benefitsData) => {
    return benefitsData.node._rawContent && benefitsData.node._rawContent.map(x => {
      if (x.style === "h2") {
        return <h2>{x.children[0].text}</h2>
      }
      if (x.style === "h3") {
        return <h3>{x.children[0].text}</h3>
      }
      if (x.style === "normal" && x.listItem === "bullet") {
        return <li>{x.children[0].text}</li>
      }
      if (x.style === "normal" && x.listItem !== "bullet") {
        return <p>{x.children[0].text}</p>
      }
    })
  }

  return (
    <section className={`${styles.mediaContainer}`}>
      <div className={`${styles.benefitsWrapper}`}>
        <div className={`${styles.leftBenefits}`}>
          {leftBenefit(benefitsData.edges[2])}
        </div>
        <div className={`${styles.middleBenefits}`}>
          {middleBenefit(benefitsData.edges[0])}
          {isLoggedIn() ? 
            <Button disableElevation variant="contained" color="primary" onClick={() => toggleUploadPopup()}>
              {t.get(translations, 'big-wins-upload-your-bigwin-now')}
            </Button>
          :
            <Button disableElevation variant = "contained" color = "primary" onClick = { () => toggleLoginPopup(0) }>
              {t.get(translations, 'big-wins-create-a-free-account-now')}
            </Button>
          }
          <Login isOpen={loginOpen} tabIndex={loginIndex} lpClose={lpClose} />
        </div>
        <div className={`${styles.rightBenefits}`}>
          {rightBenefit(benefitsData.edges[1])}
        </div>
        <MediaUploader isOpen={uploadOpen} getUser={user} mediaUploadClose={mediaUploadClose} />
      </div>
      <MediaSearch
        headerFilters={mediaFilters}
        items={mediaItems}
        setMediaItems={setMediaItems}
        hideSkeletons={hideSkeletons}
        games={gamesData}
        casinos={casinosData}
      />
    </section>
  )
}

const BigWins = ({ pageContext, data, location }) => {

  let gamesData = data.allSanityGame
  let casinosData = data.allSanityCasino
  let benefitsData = data.allSanityContentSection

  let pageData = data.sanityGenericLandingPage
  if (!pageData) pageData = {}
  const { locale, alternateLocaleSlugs } = pageContext

  let item = videoItem({ title: '', type: '', likes: 0, User: { username: '' }, date: ''})
  let [ mediaItems, setMediaItems ] = useState([item])
  let [ hideSkeletons, setHideSkeletons ] = useState(false)
  let { translations, bonuses, path } = useContext(ThemeContext)

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal
    async function fetchData() {
      fetchMedias('video', null, signal)
      .then(items => {
        setHideSkeletons(true)
        setMediaItems( items.medias.map(item => videoItem(item) ))
      })
      .catch(err => console.log(err))
    }
    fetchData()
    return function cleanup() {
      abortController.abort()
    }
  }, [])


  return [
    <SEO title={pageData.seoTitle} location={location} meta={pageData.seoMeta}  />,
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <div>
          {modal ? (
            <Router basepath={`/:lang/${t.get(translations, 'big-wins')}/w/`}>
              <Media
                path="/:stub"
                location={location}
                mediaItems={mediaItems}
                setMediaItems={setMediaItems}
              />
            </Router>
        ) : (
              <Layout silo={pageData.sanitySilo} pageContext={pageContext} location={location}>
                <Router basepath={`/:lang/${t.get(translations, 'big-wins')}/`}>
                  { !location.pathname.includes('/w/') ?
                    <ContentWrapper
                      default
                      title={pageData.h1Tag}
                      headerClassName={`gambleGeneralWrapper`}
                      bodyClassName={`gambleGeneralWrapper`}
                      bannerUrl={`/icons/Big-Win-withoutbutton.jpg`}
                      specialClassName="big-wins-page"
                    >
                      <Content default
                               translations={translations}
                               locale={locale}
                               mediaItems={mediaItems}
                               setMediaItems={setMediaItems}
                               hideSkeletons={hideSkeletons}
                               gamesData={gamesData}
                               casinosData={casinosData}
                               benefitsData={benefitsData}
                      />
                    </ContentWrapper>
                    :
                    <Media
                      path="/w/:stub"
                      location={location}
                      mediaItems={mediaItems}
                    />
                  }

                </Router>
              </Layout>
          )}
        </div>
      )}
    </ModalRoutingContext.Consumer>
  ]
}

export default localize(BigWins)
export const query = graphql`
  query BigWinsPage {
    sanitySilo (_id: {eq: "0feeb91c-44fe-4139-8a63-699d7a14b46b"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    sanityGenericLandingPage(
      name: { eq: "big-slot-wins" }
    ) {
      ...GenericLanding
    }
    allSanityContentSection {
      edges {
        node {
          name
          _rawContent(resolveReferences: {maxDepth: 10})
        }
      }
    }
    allSanityCasino {
      edges {
        node {
          name
        }
      }
    }
    allSanityGame {
      edges {
        node {
          name
        }
      }
    }
  }
`