import { Checkbox, FormControl, FormControlLabel, Grid, NativeSelect, Slider } from "@material-ui/core"

import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import t from "../../services/GetTranslationValue"

import React from "react"
import styles from "../search-box/media-search-filters.module.scss"
import CasinoBonusHelpers from "../../services/casino-bonus-helpers"
import {
  HasExclusive,
  HasNoAccount, HasNumberOfGames,
  HasVipProgram, HasWageringRequirement,
  IsNonSticky, IsTaxExempt, labelWithNumber,
} from "./search-helpers"
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SearchBox from "../search-box/search-box"


const MediaSearchFilters = ({ translations, mediaItems, games, casinos, game, setGame, casino, setCasino, searchByUsername, setSearchByUsername }) => {

  const GambleAutoComplete = ({ options, title, value, setValue, size }) => {
    return (
      <div className={`${styles.gambleAutoComplete}`}>
        {/* <label>{title}</label> */}
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          id={`big-win-${title}`}
          options={options.map((option) => option.node.name)}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          freeSolo
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size={size} placeholder={title} />
          )}
        />
      </div>
    )
  }

  return (<div id={`media-search-filters`} className={`${styles.mediaSearchFilters}`}>
    <div className={`${styles.mediaFiltersWrapper}`}>
      <p className={`${styles.optionsTitle}`}>{t.get(translations, 'big-wins-advanced-filters')}</p>
      <GambleAutoComplete
        translations={translations}
        size={"small"}
        options={games.edges}
        title={t.get(translations, 'big-wins-filter-by-game')}
        value={game}
        setValue={setGame}
      />
      <GambleAutoComplete
        translations={translations}
        size={"small"}
        options={casinos.edges}
        title={t.get(translations, 'big-wins-filter-by-casino')}
        value={casino}
        setValue={setCasino}
      />
      <span className={`${styles.userIdSearch}`}>
        <SearchBox search={searchByUsername} setSearch={setSearchByUsername} placeholderText={'big-wins-filter-by-username'} />
      </span>
    </div>
</div>)
}

export default MediaSearchFilters