export const videoItem = (item) => ({
    game: item.gameId,
    casino: item.casinoId,
    username: item.User.username,
    id: item.id,
    title: item.title,
    type: item.type,
    bet: item.bet,
    win: item.win,
    currency: item.currency,
    likes: item.likes,
    date: item.createdAt,
    videoDuration: "",
    coverPic: "",
    userVote : item.userVote,
    mediaUrl: item.url,
    mediaId: item.extMediaId
})
