import React, { useContext, useState, useEffect } from "react"
import styles from "./media-search.module.scss"
import {
  Grid,
  Button,
  Dialog,
  IconButton,
  Radio
} from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchBox from "../search-box/search-box"
import ThemeContext from "../../context/ThemeContext"
import t from "../../services/GetTranslationValue"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { getCasinosFoundText, getAllWageringRequirementTypesUsed } from "../cards-search/search-functions"
import { getAllMediaFilterNames, getDefaultFilterNames } from "../../services/preset-filters/get-all-filters"
import SearchIcon from '@material-ui/icons/Search';
import Slider from "../slider/slider"
import MediaSearchFilters from "../search-box/media-search-filters"
import MediaItem from "./media-item"
import CasinoBonusHelpers from "../../services/casino-bonus-helpers"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import { videoItem } from "../../templates/big-wins/video-item"
import { fetchMediasWithFilter } from "../../api/client/media"

const HeaderLine = ({ h1Tag, pTag }) => {
  return (
    h1Tag ? <div className={`${styles.mainSearchTitle}`}>
      <h1>{h1Tag}</h1>
    </div> : ""
  )
}

const MediaSearch = ({ h1Tag, items, setMediaItems, headerFilters, hideSkeletons, games, casinos }) => {

  const getfilteredMedia = (items, loading) => {
    return(
      <section className={`${styles.mediaContainer}`}>
        { items && items.map(item =>
            <MediaItem key={item.title.toLowerCase().split(' ').join('-') + `-${item.id}`}
              path={item.title.toLowerCase().split(' ').join('-') + `-${item.id}`}
              translations={translations}
              locale={locale}
              x={item}
              setMediaItems={setMediaItems}
              hideSkeletons={hideSkeletons}
            />)
        }
      </section>
    )
  }

  const site = useContext(ThemeContext)
  let { translations, ranking, locale, loading } = site

  const openFilters = () => {
    setFiltersVisible(true);
  };

  const closeFilters = () => {
    setFiltersVisible(false);
  };

  // setup filter states.
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [rating, setRating] = useState(3)
  const [search, setSearch] = useState("")
  const [currency, setCurrency] = useState("All")
  const [game, setGame] = useState("")
  const [casino, setCasino] = useState("")
  const [searchByUsername, setSearchByUsername] = useState("")
  const [showItems, setShowItems] = useState(12);
  const [viewAll, setViewAll] = useState(false)
  const [biggestWins, setBiggestWins] = useState(false)
  const [quickFilter, setQuickFilter] = useState(0);
  const [desktopFiltersOpen, setDesktopFiltersOpen] = useState(false);
  const [mobileFiltersSearch, setMobileFiltersSearch] = useState(false);
  const anchorRef = React.useRef(null);

  const refetchMedias = () => {
    let filters = {}

    if (searchByUsername) filters.username = searchByUsername
    if (search) filters.title = search
    if (currency !== 'All') filters.currency = currency
    if (game) filters.gameId = game
    if (biggestWins) filters.orderBy = 'win'
    let orderBy = ''
    if (quickFilter) {
      switch(quickFilter) {
        case t.get(translations, 'filter-big-wins-bet') : orderBy = 'bet'; break;
        case t.get(translations, 'filter-big-wins-biggest-win') : orderBy = 'win'; break;
        case t.get(translations, 'filter-big-wins-win') : orderBy = 'x_win'; break;
        case t.get(translations, 'filter-big-wins-latest') : orderBy = 'updatedAt'; break;
      }
    }

    filters.orderBy = orderBy || 'id'
    //console.log(filters)
    fetchMediasWithFilter('both', filters)
    .then(items => {
      setMediaItems( items.medias.map(item => {
        return videoItem(item)
      } ))
    })
    .catch(err => console.log(err))
  }

  const handleSelectedFilter = (event, newFilter) => {
    setQuickFilter(newFilter);
    setDesktopFiltersOpen(false);
  };

  const handleAdvancedFilters = () => {
    setDesktopFiltersOpen(!desktopFiltersOpen);
    setQuickFilter(0);
  };

  const handleMobileAdvancedFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const closeAdvancedFilters = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setDesktopFiltersOpen(false);
  };

  const toggleMobileFiltersSearch = () => {
    setMobileFiltersSearch(!mobileFiltersSearch);
  };

  let filters = headerFilters || getAllMediaFilterNames(translations);
  let displayMedia = getfilteredMedia(items, loading);
  let filteredMedia = items

  useEffect(() => {
    refetchMedias()
  }, [currency, search, searchByUsername, quickFilter, biggestWins, game])

  return (
    <Grid id={'search-box'} container spacing={0} className={`${styles.bonusCards}`}>
      <Grid item xs={12}>
        <HeaderLine h1Tag={h1Tag} />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        className={`${styles.bonusCardsFilter}`}
      >
        <div className={`${styles.quickFilters}`}>
          {/* <span className={`gamble-hidden-md-up`} onClick={toggleMobileFiltersSearch}>
            {mobileFiltersSearch ?
              <CloseIcon className={`${styles.mobileSearchToggle}`} />
              :
              <SearchIcon className={`${styles.mobileSearchToggle}`} />
            }
          </span> */}
          {/* <span className={`gamble-hidden-lg-up gamble-hidden-md-down ${styles.qfSearch}`}>
            <SearchBox search={search} setSearch={setSearch} />
          </span> */}
          <span className={`${styles.qfTitle}`}>{t.get(translations, 'big-wins-count')}: {filteredMedia.length}</span>
          { <div className={`qfb-container ${styles.qfbContainer}`}>
            <div className={`${styles.qfbParent}`}>
              <Slider disableNav={true}>
                <div className={`${styles.mediaFiltersWrapper}`}>
                  <ToggleButtonGroup value={quickFilter} exclusive onChange={handleSelectedFilter}>
                    {
                      filters.map((x, index) =>
                        <ToggleButton key={index + 1} component="a" value={x.baseName} className={`${styles.qfButton}`}>
                          <Radio
                            checked={quickFilter === x.baseName}
                            value={x.baseName}
                            name="quick-filters-icon"
                            className={`${styles.qfButtonIcon}`}
                            inputProps={{ 'aria-label': 'quick filters icon' }}
                          />
                          <span className={`${styles.qfButtonText}`}>{x.baseName}</span>
                        </ToggleButton>)
                    }
                  </ToggleButtonGroup>
                  <NativeSelect
                    labelId="win-currency-filter-label"
                    id="win-currency-filter"
                    value={currency}
                    onChange={e => setCurrency(e.target.value)}
                    variant="outlined"
                    className={`${styles.currencyFilter}`}
                  >
                    <option value={"All"}>{t.get(translations, "all-currencies")}</option>
                    <option value={"€"}>€</option>
                    <option value={"£"}>£</option>
                    <option value={"$"}>$</option>
                  </NativeSelect>
                </div>
              </Slider>
            </div>
          </div> }
{/*           <div className={`gamble-hidden-md-down qfb-container ${styles.advancedFiltersButton}`}>
            <ToggleButton
              value="check"
              selected={desktopFiltersOpen}
              onChange={handleAdvancedFilters}
              ref={anchorRef}
              component="a"
            >
              <ExpandMoreIcon />
            </ToggleButton>
          </div>
          <span className={`gamble-hidden-md ${styles.qfSearch} ${mobileFiltersSearch ? styles.active : ""}`}>
            <SearchBox search={search} setSearch={setSearch} />
          </span>
 */}
          <div anchorEl={anchorRef.current}>
            <ClickAwayListener onClickAway={closeAdvancedFilters}>
              <div className={`${desktopFiltersOpen ? styles.afOpen : styles.afClose} ${styles.desktopAdvancedFilters}`}>
                <MediaSearchFilters
                  translations={translations}
                  games={games}
                  casinos={casinos}
                  game={game}
                  setGame={setGame}
                  casino={casino}
                  setCasino={setCasino}
                  searchByUsername={searchByUsername}
                  setSearchByUsername={setSearchByUsername}
                  />
              </div>
            </ClickAwayListener>
          </div>
        </div>

      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        className={`${styles.cardsContainer}`}
      >
        {/* <div className={`${styles.updateDate}`}>
          <div>
            <label>
              {t.get(translations, "bonus-cards-listing-last-updated")}:
            </label>
          </div>
        </div> */}
        <span id={'bonus-start'}></span>
        {displayMedia}
      </Grid>
        {/* Mobile filters */}
        <Grid item xs={12} className={`gamble-hidden-md-up ${styles.mobileFilters}`}>
          <div className={`${styles.container} ${styles.filtersNormal} ${styles.filtersElevated}`}>
            <Button
              color="primary"
              className={`${styles.filtersButton}`}
              onClick={openFilters}
            >
              <FilterListIcon />
              {t.get(translations, 'search-filter')}
            </Button>
            <Dialog fullScreen open={filtersVisible} onClose={closeFilters}>
              <div className={`${styles.filtersDialog}`}>
                <a href="#bonus-start" aria-label={t.get(translations, "search-apply-filters")} style={{ textDecoration: 'none' }}>
                  <IconButton disableRipple edge="start" color="primary" onClick={closeFilters} aria-label="close" className={`${styles.filtersCloseButton}`}>
                    <CloseIcon />
                  </IconButton>
                </a>
                <div className={`${styles.search}`}>
                  <SearchBox search={search} setSearch={setSearch} />
                </div>
                <p className={`${styles.casinoResultCount}`}>{filteredMedia.length} {getCasinosFoundText(filteredMedia.length, translations)}</p>
                {/* <MediaSearchFilters
                  activeBonuses={filteredMedia} translations={translations} /> */}
                <MediaSearchFilters
                  translations={translations}
                  games={games}
                  casinos={casinos}
                  game={game}
                  setGame={setGame}
                  casino={casino}
                  setCasino={setCasino}
                  searchByUsername={searchByUsername}
                  setSearchByUsername={setSearchByUsername}
                />
                <div className={`${styles.filtersApplyButtonContainer}`}>
                  <a href="#bonus-start" aria-label={t.get(translations, "search-apply-filters")} style={{textDecoration:'none'}}>
                    <Button variant="contained" color="primary" onClick={closeFilters} className={`${styles.filtersApplyButton}`}>
                      {t.get(translations, 'search-apply-filters')}
                    </Button>
                  </a>
                </div>
              </div>
            </Dialog>
          </div>
        </Grid>
      {/* <Grid item xs={12} className={`${styles.viewAll}`}>
        <Button
          variant="contained"
          color="secondary"
          style={{ display: showItems < filteredMedia.length ? "" : "none"}}
          className={`${styles.viewAllButton}`}
          onClick={() => setShowItems(showItems + 10)}
        >
          {t.get(translations, "show-more")}
        </Button>
      </Grid> */}
    </Grid>
  )
}

export default MediaSearch
